export const PLATFORMS = {
    GITHUB: 'GitHub',
    FRONTEND_MENTOR: 'Frontend Mentor',
    TWITTER: 'Twitter',
    LINKEDIN: 'LinkedIn',
    YOUTUBE: 'YouTube',
    FACEBOOK: 'Facebook',
    TWITCH: 'Twitch',
    DEVTO: 'Dev.to',
    CODEWARS: 'Codewars',
    CODEPEN: 'Codepen',
    FREE_CODE_CAMP: 'freeCodeCamp',
    GITLAB: 'GitLab',
    HASHNODE: 'Hashnode',
    STACK_OVERFLOW: 'Stack Overflow'
}